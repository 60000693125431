import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';

class SideBar extends React.Component {
  componentDidMount() {
    $('.sidebar .sidebar-menu li a').on('click', function() {
      const $this = $(this);

      if ($this.parent().hasClass('open')) {
        $this
          .parent()
          .children('.dropdown-menu')
          .slideUp(200, () => {
            $this.parent().removeClass('open');
          });
      } else {
        $this
          .parent()
          .parent()
          .children('li.open')
          .children('.dropdown-menu')
          .slideUp(200);

        $this
          .parent()
          .parent()
          .children('li.open')
          .children('a')
          .removeClass('open');

        $this
          .parent()
          .parent()
          .children('li.open')
          .removeClass('open');

        $this
          .parent()
          .children('.dropdown-menu')
          .slideDown(200, () => {
            $this.parent().addClass('open');
          });
      }
    });

    // Sidebar Activity Class
    const sidebarLinks = $('.sidebar').find('.sidebar-link');

    sidebarLinks
      .each((index, el) => {
        $(el).removeClass('active');
      })
      .filter(function() {
        const href = $(this).attr('href');
        const pattern = href[0] === '/' ? href.substr(1) : href;
        return pattern === (window.location.pathname).substr(1);
      })
      .addClass('active');

    // ٍSidebar Toggle
    $('#sidebar-toggle').on('click', e => {
      $('body').toggleClass('is-collapsed');
      e.preventDefault();
    });

    /**
     * Wait untill sidebar fully toggled (animated in/out)
     * then trigger window resize event in order to recalculate
     * masonry layout widths and gutters.
     */
    // $('#sidebar-toggle').click(e => {
    //   alert("FFF");
    //   console.log(e);
    //   $('#sidebar-toggle').addClass('fuckfuck');
    //   $(this).addClass('is-collapsed');
    //   e.preventDefault();
    //   // setTimeout(() => {
    //   //   window.dispatchEvent(window.EVENT);
    //   // }, 300);
    // });
  }

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-inner">
          <div className="sidebar-logo">
            <div className="peers ai-c fxw-nw">
              <div className="peer peer-greed">
                <a className="sidebar-link td-n" href="/">
                  <div className="peers ai-c fxw-nw">
                    <div className="peer">
                      <div className="logo">
                        <img src="/assets/images/logo.jpg" alt="" style={{ width: '70px' }} />
                      </div>
                    </div>
                    <div className="peer peer-greed">
                      <h5 className="lh-1 mB-0 logo-text">&nbsp;&nbsp;Skywise</h5>
                    </div>
                  </div>
                </a>
              </div>
              <div className="peer">
                <div className="mobile-toggle sidebar-toggle">
                  <a href="/" className="td-n">
                    <i className="ti-arrow-circle-left"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ul className="sidebar-menu scrollable pos-r">
            <li className="nav-item mT-30">
              <a className="sidebar-link" href="/">
                <span className="icon-holder">
                  <i className="c-teal-500 ti-home"></i>
                </span>
                <span className="title">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="sidebar-link" href="/operations-report">
                <span className="icon-holder">
                  <i className="c-blue-500 ti-bar-chart"></i>
                </span>
                <span className="title">Operations Report</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="sidebar-link" href="/oil-consumption">
                <span className="icon-holder">
                  <i className="c-orange-500 ti-dashboard"></i>
                </span>
                <span className="title">Oil Consumption</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="sidebar-link" href="/recurrent-defects">
                <span className="icon-holder">
                  <i className="c-pink-500 ti-alert"></i>
                </span>
                <span className="title">Recurrent Defects </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {})(SideBar);