import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import axios from 'axios';
import { Table, Input, InputNumber, Popconfirm, Form, message } from 'antd';
import AmCharts from '@amcharts/amcharts3-react';
const BEARER_TOKEN = 'eyJwbG50ciI6InRlR0FHZ3dkUVg2V0ZIZ3V4dlRcLzhBPT0iLCJhbGciOiJFUzI1NiJ9.eyJzdWIiOiJSckJxY2prTFQybUhyRmlUalpCU2hnPT0iLCJqdGkiOiJEeTVVTmQ5YVJiYUpQbEx6OE4rZXpnPT0ifQ.yNoF6oKklgjBDjo9QZs4CEN6jrt_poDhnJZUk1LC4XDnjcXMICx4E5Pm0dpIyOsVEqfMCAfNR1SinJ0w_uiC_A';
const CLIENT_ID = 'a5bf0ca664f7461ba6a116983c7dc674';
const CLIENT_SECRET = '426e89279c3748af87b73f7cfeBd7697';
const FormItem = Form.Item;
class OilConsumption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chart1Data: [],
      chart2Data: [],
      chart3Data: []
    };
  }

  componentDidMount() {
    setInterval(() => {
      $("[title='JavaScript charts']").remove();
    }, 3000);
    this.getChart1Data();
    this.getChart2Data();
    this.getChart3Data();
  }

  getChart1Data(){
    return axios.get(
        'https://api.airbus.com/sdx-oil-conspt-v1/api/oil-consumptions-by-aircraft-reg?start_date=2019-01-01&end_date=2019-01-05&aircraft_reg=SW-H3318&flight_hours=100',
        { 
          headers: {  
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${BEARER_TOKEN}`,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET
          } 
        }
      ).then(response => {
        if(response && response.data && response.data.length > 0){
          const dataChart1 = response.data.map((i) => {
            return {
              category: i.actual_time_departure,
              value1: i.fuel_landing,
              value2: i.fuel_takeoff,
              value3: i.fuel_planned_block,
              value4: i.fuel_actual_block,
              value5: i.fuel_planned_flight,
            }
          });
          this.setState({chart1Data: dataChart1 || []});
        }
      }).catch((error) => {
        message.error(JSON.stringify(error.message));
      });
  }

  getChart2Data(){
    return axios.get(
      'https://api.airbus.com/sdx-oil-conspt-v1/api/oil-consumptions-by-aircraft-reg?start_date=2019-01-01&end_date=2019-01-05&flight_hours=100',
      { 
        headers: {  
          Authorization: `Bearer ${BEARER_TOKEN}`,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET
        } 
      }
    ).then(response => {
      if(response && response.data && response.data.result && response.data.result.length > 0){
        const dataChart2 = response.data.result.map((i) => {
          return {
            category: i.aircraft_registration,
            value1: i.oil_consumption.map(oil_consumption => oil_consumption.landing_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value2: i.oil_consumption.map(oil_consumption => oil_consumption.takeoff_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value3: i.oil_consumption.map(oil_consumption => oil_consumption.planned_block_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value4: i.oil_consumption.map(oil_consumption => oil_consumption.actual_block_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value5: i.oil_consumption.map(oil_consumption => oil_consumption.planned_flight_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
          }
        });
        this.setState({chart2Data: dataChart2 || []});
      }
    }).catch((error) => {
      message.error(JSON.stringify(error.message));
    });
  }

  getChart3Data(){
    return axios.get(
      'https://api.airbus.com/sdx-oil-conspt-v1/api/oil-consumptions-by-aircraft-type?start_date=2019-01-01&end_date=2019-01-05',
      { 
        headers: {  
          Authorization: `Bearer ${BEARER_TOKEN}`,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET
        } 
      }
    ).then(response => {
      if(response && response.data && response.data.result && response.data.result.length > 0){
        const dataChart3 = response.data.result.map((i) => {
          return {
            category: i.aircraft_type,
            value1: i.oil_consumption.map(oil_consumption => oil_consumption.landing_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value2: i.oil_consumption.map(oil_consumption => oil_consumption.takeoff_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value3: i.oil_consumption.map(oil_consumption => oil_consumption.planned_block_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value4: i.oil_consumption.map(oil_consumption => oil_consumption.actual_block_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
            value5: i.oil_consumption.map(oil_consumption => oil_consumption.planned_flight_fuel_kilograms).reduce((total, currentValue) => total + currentValue),
          }
        });
        this.setState({chart3Data: dataChart3 || []});
      }
    }).catch((error) => {
      message.error(JSON.stringify(error.message));
    });
  }

  render() {
    const { chart1Data, chart2Data, chart3Data } = this.state;
    return (
      <main className="main-content bgc-grey-100">
        <div id="mainContent">
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Mean Oil Consumption of Aircraft "SW-H3318" from <b><i>01/09/2019</i></b> to <b><i>05/01/2019</i></b></h6>
                <div className="mT-30">
                  <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "type": "serial",
                      "theme": "light",
                      "legend": {
                        "useGraphSettings": true
                      },
                      "dataProvider": chart1Data,
                      "startEffect": "easeOutSine",
                      "valueAxes": [{
                        "position": "left",
                        "axisAlpha":0,
                      }],
                      "gridAboveGraphs": true,
                      "startDuration": 1,
                      "graphs": [{
                        "title": "Takeoff Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "blue",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "blue",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value2"
                      }, {
                        "title": "Landing Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "red",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "red",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value1"
                      },
                      {
                        "title": "Planned Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "green",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "green",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value3"
                      }, {
                        "title": "Actual Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "purple",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "purple",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value4"
                      }, {
                        "title": "Planned Flight Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "orange",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "orange",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value5"
                      }],
                      "chartCursor": {
                        "categoryBalloonEnabled": false,
                        "cursorAlpha": 0,
                        "zoomable": false
                      },
                      "categoryField": "category",
                      "categoryAxis": {
                        "gridPosition": "start",
                        "gridAlpha": 0
                      },
                    }} />
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Mean of Oil Consumption by Aircraft Registration</h6>
                <div className="mT-30">
                  <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "theme": "light",
                      "type": "serial",
                      "dataProvider": chart2Data,
                      "valueAxes": [{
                        "position": "left",
                        "axisAlpha":0,
                      }],
                      "startDuration": 1,
                      "graphs": [{
                        "title": "Takeoff Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "blue",
                        "bulletColor": "blue",
                        "fillAlphas": 1,
                        "fillColors": "blue",
                        "type": "column",
                        "valueField": "value2"
                      }, {
                        "title": "Landing Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "red",
                        "bulletColor": "red",
                        "fillAlphas": 1,
                        "fillColors": "red",
                        "type": "column",
                        "valueField": "value1"
                      }, {
                        "title": "Planned Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "green",
                        "bulletColor": "green",
                        "fillAlphas": 1,
                        "fillColors": "green",
                        "type": "column",
                        "valueField": "value3"
                      }, {
                        "title": "Actual Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "purple",
                        "bulletColor": "purple",
                        "fillAlphas": 1,
                        "fillColors": "purple",
                        "type": "column",
                        "valueField": "value4"
                      }, {
                        "title": "Planned Flight Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "orange",
                        "bulletColor": "orange",
                        "fillAlphas": 1,
                        "fillColors": "orange",
                        "type": "column",
                        "valueField": "value5"
                      }],
                      "chartCursor": {
                        "categoryBalloonEnabled": false,
                        "cursorAlpha": 0,
                        "zoomable": false
                      },
                      "categoryField": "category",
                      "categoryAxis": {
                        "gridPosition": "start",
                      },
                      "legend": {
                        "generateFromData": true,
                        "position": "bottom",
                        "autoMargins": true
                      },
                      "export": {
                        "enabled": true
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Mean of Oil Consumption by Aircraft Type</h6>
                <div className="mT-30">
                <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "theme": "light",
                      "type": "serial",
                      "dataProvider": chart3Data,
                      "valueAxes": [{
                        "position": "left",
                        "axisAlpha":0,
                      }],
                      "startDuration": 1,
                      "graphs": [{
                        "title": "Takeoff Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "blue",
                        "bulletColor": "blue",
                        "fillAlphas": 1,
                        "fillColors": "blue",
                        "type": "column",
                        "valueField": "value2"
                      }, {
                        "title": "Landing Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "red",
                        "bulletColor": "red",
                        "fillAlphas": 1,
                        "fillColors": "red",
                        "type": "column",
                        "valueField": "value1"
                      }, {
                        "title": "Planned Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "green",
                        "bulletColor": "green",
                        "fillAlphas": 1,
                        "fillColors": "green",
                        "type": "column",
                        "valueField": "value3"
                      }, {
                        "title": "Actual Block Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "purple",
                        "bulletColor": "purple",
                        "fillAlphas": 1,
                        "fillColors": "purple",
                        "type": "column",
                        "valueField": "value4"
                      }, {
                        "title": "Planned Flight Fuel Kilograms",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "lineColor": "orange",
                        "bulletColor": "orange",
                        "fillAlphas": 1,
                        "fillColors": "orange",
                        "type": "column",
                        "valueField": "value5"
                      }],
                      "chartCursor": {
                        "categoryBalloonEnabled": false,
                        "cursorAlpha": 0,
                        "zoomable": false
                      },
                      "categoryField": "category",
                      "categoryAxis": {
                        "gridPosition": "start",
                      },
                      "legend": {
                        "generateFromData": true,
                        "position": "bottom",
                        "autoMargins": true
                      },
                      "export": {
                        "enabled": true
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {})(OilConsumption);