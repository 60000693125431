import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import AmCharts from '@amcharts/amcharts3-react';

class Dashboard extends React.Component {
  componentDidMount() {
    setInterval(() => {
      $("[title='JavaScript charts']").remove();
      $("[title='Interactive JavaScript maps']").remove();
    }, 1000);
  }

  render() {
    var targetSVG = "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";
    var planeSVG = "m2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47";
    return (
      <main className="main-content bgc-grey-100">
        <div id="mainContent">
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item  w-100">
              <div className="row gap-20">
                <div className="col-md-12">
                  <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "type": "map",
                      "theme": "light",
                      "dataProvider": {
                        "map": "worldLow",
                        "zoomLevel": 3.5,
                        "zoomLongitude": -55,
                        "zoomLatitude": 42,

                        "lines": [{
                          "id": "line1",
                          "arc": -0.85,
                          "alpha": 0.3,
                          "latitudes": [48.8567, 43.8163, 34.3, 23],
                          "longitudes": [2.3510, -79.4287, -118.15, -82]
                        }, {
                          "id": "line2",
                          "alpha": 0,
                          "lineColor": "red",
                          "color": "red",
                          "latitudes": [48.8567, 43.8163, 34.3, 23],
                          "longitudes": [2.3510, -79.4287, -118.15, -82]
                        }],
                        "images": [{
                          "svgPath": targetSVG,
                          "title": "Paris",
                          "latitude": 48.8567,
                          "longitude": 2.3510
                        }, {
                          "svgPath": targetSVG,
                          "title": "Toronto",
                          "latitude": 43.8163,
                          "longitude": -79.4287
                        }, {
                          "svgPath": targetSVG,
                          "title": "Los Angeles",
                          "latitude": 34.3,
                          "longitude": -118.15
                        }, {
                          "svgPath": targetSVG,
                          "title": "Havana",
                          "latitude": 23,
                          "longitude": -82
                        }, {
                          "svgPath": planeSVG,
                          "positionOnLine": 0,
                          "color": "#000000",
                          "alpha": 0.1,
                          "animateAlongLine": true,
                          "lineId": "line2",
                          "flipDirection": true,
                          "loop": true,
                          "scale": 0.03,
                          "positionScale": 1.3
                        }, {
                          "svgPath": planeSVG,
                          "positionOnLine": 0,
                          "color": "#585869",
                          "animateAlongLine": true,
                          "lineId": "line1",
                          "flipDirection": true,
                          "loop": true,
                          "scale": 0.03,
                          "positionScale": 1.8
                        }]
                      },

                      "areasSettings": {
                        "unlistedAreasColor": "#8dd9ef"
                      },

                      "imagesSettings": {
                        "color": "#585869",
                        "rollOverColor": "#585869",
                        "selectedColor": "#585869",
                        "pauseDuration": 0.2,
                        "animationDuration": 2.5,
                        "adjustAnimationSpeed": true
                      },

                      // "linesSettings": {
                      //   "color": "red",
                      //   "alpha": 0.4
                      // },

                      "export": {
                        "enabled": true
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row gap-20 masonry pos-r">
                <div className="masonry-item col-md-4">
                  <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">Delay Between 2 week</h6>
                    <div className="mT-30">
                      <AmCharts.React
                        style={{
                          width: '100%',
                          height: '500px',
                        }}
                        options={{
                          "theme": "light",
                          "type": "serial",
                          "dataProvider": [{
                            "country": "Delay Flight",
                            "year2004": 100,
                            "year2005": 200
                          }],
                          "valueAxes": [{
                            "stackType": "3d",
                            "unit": "%",
                            "position": "left",
                          }],
                          "startDuration": 1,
                          "graphs": [{
                            "balloonText": "This week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2004",
                            "type": "column",
                            "valueField": "year2004"
                          }, {
                            "balloonText": "Last week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2005",
                            "type": "column",
                            "valueField": "year2005"
                          }],
                          "plotAreaFillAlphas": 0.1,
                          "depth3D": 60,
                          "angle": 30,
                          "categoryField": "country",
                          "categoryAxis": {
                            "gridPosition": "start"
                          },
                          "export": {
                            "enabled": true
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="masonry-item col-md-4">
                  <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">Oil Over Average Between 2 week</h6>
                    <div className="mT-30">
                      <AmCharts.React
                        style={{
                          width: '100%',
                          height: '500px',
                        }}
                        options={{
                          "theme": "light",
                          "type": "serial",
                          "dataProvider": [{
                            "country": "Oil",
                            "year2004": 100,
                            "year2005": 200
                          }],
                          "valueAxes": [{
                            "stackType": "3d",
                            "unit": "%",
                            "position": "left",
                          }],
                          "startDuration": 1,
                          "graphs": [{
                            "balloonText": "This week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2004",
                            "type": "column",
                            "valueField": "year2004"
                          }, {
                            "balloonText": "Last week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2005",
                            "type": "column",
                            "valueField": "year2005"
                          }],
                          "plotAreaFillAlphas": 0.1,
                          "depth3D": 60,
                          "angle": 30,
                          "categoryField": "country",
                          "categoryAxis": {
                            "gridPosition": "start"
                          },
                          "export": {
                            "enabled": true
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="masonry-item col-md-4">
                  <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">Defect Issue Between 2 week</h6>
                    <div className="mT-30">
                      <AmCharts.React
                        style={{
                          width: '100%',
                          height: '500px',
                        }}
                        options={{
                          "theme": "light",
                          "type": "serial",
                          "dataProvider": [{
                            "country": "Defect",
                            "year2004": 100,
                            "year2005": 200
                          }],
                          "valueAxes": [{
                            "stackType": "3d",
                            "unit": "%",
                            "position": "left",
                          }],
                          "startDuration": 1,
                          "graphs": [{
                            "balloonText": "This week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2004",
                            "type": "column",
                            "valueField": "year2004"
                          }, {
                            "balloonText": "Last week: <b>[[value]]</b>",
                            "fillAlphas": 0.9,
                            "lineAlpha": 0.2,
                            "title": "2005",
                            "type": "column",
                            "valueField": "year2005"
                          }],
                          "plotAreaFillAlphas": 0.1,
                          "depth3D": 60,
                          "angle": 30,
                          "categoryField": "country",
                          "categoryAxis": {
                            "gridPosition": "start"
                          },
                          "export": {
                            "enabled": true
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {})(Dashboard);