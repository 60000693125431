import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Table, Input, InputNumber, Popconfirm, Form } from 'antd';
import AmCharts from '@amcharts/amcharts3-react';
const FormItem = Form.Item;
class RecurrentDefects extends React.Component {
  componentDidMount() {
    setInterval(() => {
      $("[title='JavaScript charts']").remove();
    }, 1000);
  }

  render() {
    const columns1 = [{
      title: 'Aircraft Registration',
      dataIndex: 'aircraft_registration',
      key: 'aircraft_registration',
    }, {
      title: 'TC-NBC',
      dataIndex: 'tc_nbc',
      key: 'tc_nbc',
    }, {
      title: 'TC-NBG',
      dataIndex: 'tc_nbg',
      key: 'tc_nbg',
    }];

    const data1 = [{
      aircraft_registration: '21-61',
      tc_nbc: '1',
      tc_nbg: '',
    }, {
      aircraft_registration: '49-51',
      tc_nbc: '',
      tc_nbg: '1',
    }];

    const columns2 = [{
      title: 'Reg',
      dataIndex: 'reg',
      key: 'reg',
      width: '15%',
    }, {
      title: 'Action Taken',
      dataIndex: 'action_taken',
      key: 'action_taken',
      width: '85%',
      render: (text, row, index) =>
        <div dangerouslySetInnerHTML={{__html: text}} />
    }];

    const data2 = [{
      reg: 'Aircraft: TC-NBG ID: 69',
      action_taken: `Date: 2018-05-25 04:15:00 Action: APU SYS TEST PERFORMANCE APP. SYS TEST OK NO FAULT OBSERVER<br/>Date: 2018-05-27 00:30:00 Action: APU OP TEST PERFORMED. NORMAL.<br/>Date: 2018-05-30 06:45:00 Action: INLET PRESS XDCR (8048KM) FAULT CODE WAS TAKEN ITEM XFERRED TO HIL #051840-2 ACC TO MEL.<br/>Date: 2018-05-31 07:30:00 Action: APU "INLET PRESS XDCR (8048KM)" FAULT DETECTED. INLET PRESSURE TRANSDUCER REPLACED. APU OPERATIONAL TEST IS OK.

      HIL ITEM CLEARED
      
      AMM: 49-51-21 PB:401 R:19 01/05/2018<br/>Date: 2018-06-01 00:55:00 Action: ITEM WAS TRANSFERED TO HOLD ITEM LIST PAGE 51840 ITEM4 IN ACCORDANCE WITH MEL ITEM 49-00-01A<br/>Date: 2018-06-02 02:30:00 Action: TSM 49-00-81-810-802-A APPLIED. ALL FAULT CODES CHECKED. APU OPS. TEST PERFORMED.APU WORKS NORMALLY. HIL CLEARED.<br/>Date: 2018-06-04 08:00:00 Action: ITEM TRANSFERED TO ADL:22528-4<br/>Date: 2018-06-05 04:00:00 Action: ITEM XFERED TO HIL 51841-1
      
      INLET PRESSURE XCDR (8048KM) FAULT CODE TAKEN<br/>Date: 2018-06-05 04:10:00 Action: THIS ITEM CLOSED IAW W/O 1242502<br/>Date: 2018-06-05 23:00:00 Action: FAULT CODE: 49-51-21 024 INLET PRESS XDCR (8048) IS REPETITIVE TROUBLE SHOOTING DATA OF THIS FAULT IS INLET PRESSURE SENSOR SHOWS < 6.5 PSIA ON GND P22 ELECTRICAL CONNECTOR CLEANED CONTINUITY BETWEEN P22 AND ECB IS NORMAL FOR T/S ECB HAS BEEN REPLACED TESTED NORMAL HIL CLEARED<br/>Date: 2018-06-10 15:50:00 Action: ITEM XFERRED TO HIL#051841-3 ACC TO MEL.<br/>Date: 2018-06-11 16:45:00 Action: APU U/S ITEM XFERRED TO HIL 51841-4<br/>Date: 2018-06-11 23:50:00 Action: FUEL CTRL UNIT (8022KM)/APU FUEL SPPLY FAULT CODE DETECTED. FCU REPLACED AND APU OP. TEST PERFORMED. APU FOUND NORMAL.<br/>Date: 2018-06-12 00:00:00 Action: THIS ITEM CLOSED IAW W/O 1242522.<br/>Date: 2018-06-13 09:00:00 Action: APU IS INOP XRD TO HIL 51842-2
      
      FUEL FLOW DISAGREE WITH COMMAND & INLET PRESSURE SENSOR <6,5 PSIA ON GROUND FAULT CODE TAKEN VIA TSD<br/>Date: 2018-06-15 04:00:00 Action: FCN79 AND FCN 022 FAULT CODE TAKEN. TSM 49-00-81-810-802 A  AND TSM 49-00-81-810-860A PERFORMED.NO ABNORMAL CONDITION OBSERVED. INLET PRESSURE XDCR SWAPPED WITH TC-NBM AND FCU SWAPPED WITH LP FUEL SWITCH WITH  TC-NBA FOR FURTHER T/S.. OPERATIONAL TEST PERFORMED UNDER PNEUMATIC AND ELECTRICAL LOAD MORE THAN 1 HOUR. NO FAULT OBSERVED. NORMAL. HIL CLEARED.
      
      (INLET PRESSURE XDCR IS A CR COMPONENT. SO NO NEED FOR SWAP REPORT)
      SEE WO1239286 AND 1246117`,
    }];
    return (
      <main className="main-content bgc-grey-100">
        <div id="mainContent">
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">SHGM Report</h6>
                <div className="mT-30">
                  <Table bordered columns={columns1} dataSource={data1} />
                </div>
              </div>
            </div>
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Recurrent Defect Details</h6>
                <div className="mT-30">
                  <Table bordered columns={columns2} dataSource={data2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {})(RecurrentDefects);