import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Table, Input, InputNumber, Popconfirm, Form } from 'antd';
import AmCharts from '@amcharts/amcharts3-react';
const FormItem = Form.Item;
class OperationsReport extends React.Component {
  componentDidMount() {
    setInterval(() => {
      $("[title='JavaScript charts']").remove();
    }, 1000);
  }

  render() {
    var chartData = [];
    var firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 100);

    var visits = 1600;
    var hits = 2900;
    var views = 8700;


    for (var i = 0; i < 100; i++) {
      // we create date objects here. In your data, you can have date strings
      // and then set format of your dates using chart.dataDateFormat property,
      // however when possible, use date objects, as this will speed up chart rendering.
      var newDate = new Date(firstDate);
      newDate.setDate(newDate.getDate() + i);

      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      hits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      views += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);

      chartData.push({
        date: newDate,
        visits: visits,
        hits: hits,
        views: views
      });
    }
    const columns = [{
      title: 'Departure Date',
      dataIndex: 'departure_date',
      key: 'departure_date',
    }, {
      title: 'Flight',
      dataIndex: 'flight',
      key: 'flight',
    }, {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
    }, {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
    }];

    const data = [{
      departure_date: '07-Aug',
      flight: 'TR0189',
      route: 'HGHSIN',
      summary: 'Delayed by 55mins due to 40mins REACTIONARY',
    }, {
      departure_date: '08-Aug',
      flight: 'TR0199',
      route: 'SINPER',
      summary: 'Delayed by 160mins due to 160mins ENGINEERING',
    }, {
      departure_date: '09-Aug',
      flight: 'TR0120',
      route: 'HDWIDF',
      summary: 'Delayed by 177mins due to 27mins ATC',
    }, {
      departure_date: '10-Aug',
      flight: 'TR0089',
      route: 'KBPEFW',
      summary: 'Delayed by 55mins due to 40mins OPS',
    }, {
      departure_date: '11-Aug',
      flight: 'TR0119',
      route: 'IUWOWX',
      summary: 'Delayed by 55mins due to 40mins REACTIONARY',
    }, {
      departure_date: '12-Aug',
      flight: 'TR0189',
      route: 'SINOOL',
      summary: 'Delayed by 55mins due to 40mins REACTIONARY',
    }, {
      departure_date: '13-Aug',
      flight: 'TR0189',
      route: 'NRTTPE',
      summary: 'Delayed by 55mins due to 40mins REACTIONARY',
    }];
    return (
      <main className="main-content bgc-grey-100">
        <div id="mainContent">
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item col-md-12">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">On Time Performance Last 12 Months</h6>
                <div className="mT-30">
                  <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "type": "serial",
                      "theme": "light",
                      "legend": {
                        "useGraphSettings": true
                      },
                      "dataProvider": [{
                        "category": "July",
                        "value1": 2025,
                        "value2": 1800,
                        "value3": 1600,
                        "value4": 800,
                      }, {
                        "category": "August",
                        "value1": 1863,
                        "value2": 1951,
                        "value3": 500,
                        "value4": 1700,
                      }, {
                        "category": "September",
                        "value1": 1610,
                        "value2": 950,
                        "value3": 100,
                        "value4": 200,
                      }, {
                        "category": "October",
                        "value1": 1732,
                        "value2": 1250,
                        "value3": 400,
                        "value4": 800,
                      }, {
                        "category": "November",
                        "value1": 1123,
                        "value2": 765,
                        "value3": 900,
                        "value4": 500,
                      }, {
                        "category": "December",
                        "value1": 985,
                        "value2": 841,
                        "value3": 400,
                        "value4": 300,
                      }, {
                        "category": "January",
                        "value1": 1400,
                        "value2": 1300,
                        "value3": 800,
                        "value4": 200,
                      }, {
                        "category": "February",
                        "value1": 1400,
                        "value2": 1300,
                        "value3": 800,
                        "value4": 900,
                      }, {
                        "category": "March",
                        "value1": 1100,
                        "value2": 900,
                        "value3": 500,
                        "value4": 500,
                      }, {
                        "category": "April",
                        "value1": 1400,
                        "value2": 500,
                        "value3": 200,
                        "value4": 300,
                      }, {
                        "category": "May",
                        "value1": 1200,
                        "value2": 1300,
                        "value3": 1400,
                        "value4": 900,
                      }, {
                        "category": "June",
                        "value1": 1100,
                        "value2": 800,
                        "value3": 100,
                        "value4": 200,
                      }, {
                        "category": "July",
                        "value1": 900,
                        "value2": 1300,
                        "value3": 1200,
                        "value4": 1500,
                      }],
                      "startEffect": "easeOutSine",
                      "valueAxes": [{
                        "gridColor": "#FFFFFF",
                        "gridAlpha": 0.2,
                        "dashLength": 0
                      }],
                      "gridAboveGraphs": true,
                      "startDuration": 1,
                      "graphs": [{
                        "title": "On Time",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "red",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "red",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value1"
                      }, {
                        "title": "Within 15 Minutes",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "orange",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "orange",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value2"
                      }, {
                        "title": "Within 30 Minutes",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "green",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "green",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value3"
                      }, {
                        "title": "Within 60 Minutes",
                        "balloonText": "[[title]]: <b>[[value]]</b>",
                        "bullet": "round",
                        "bulletColor": "purple",
                        "bulletBorderColor": "#ffffff",
                        "useLineColorForBulletBorder": true,
                        "lineColor": "purple",
                        "bulletSize": 10,
                        "bulletBorderAlpha": 1,
                        "bulletBorderThickness": 2,
                        "valueField": "value4"
                      }],
                      "chartCursor": {
                        "categoryBalloonEnabled": false,
                        "cursorAlpha": 0,
                        "zoomable": false
                      },
                      "categoryField": "category",
                      "categoryAxis": {
                        "gridPosition": "start",
                        "gridAlpha": 0
                      },
                    }} />
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-20 masonry pos-r">
            <div className="masonry-item col-md-6">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Delay Reason (>= 15 Mins)</h6>
                <div className="mT-30">
                  <AmCharts.React
                    style={{
                      width: '100%',
                      height: '500px',
                    }}
                    options={{
                      "type": "pie",
                      "theme": "light",
                      "dataProvider": [{
                        "country": "AIRPORT",
                        "litres": 501.9
                      }, {
                        "country": "ATC",
                        "litres": 301.9
                      }, {
                        "country": "ENGINEERING",
                        "litres": 201.1
                      }, {
                        "country": "GHA",
                        "litres": 165.8
                      }, {
                        "country": "MISC",
                        "litres": 139.9
                      }, {
                        "country": "OPS",
                        "litres": 128.3
                      }, {
                        "country": "PAX CONVENIENT",
                        "litres": 99
                      }, {
                        "country": "REACTIONARY",
                        "litres": 60
                      }, {
                        "country": "WEATHER",
                        "litres": 50
                      }],
                      "legend": {
                        "position": "bottom",
                        "autoMargins": true
                      },
                      "valueField": "litres",
                      "titleField": "country",
                      "balloon": {
                        "fixedPosition": true
                      },
                      "export": {
                        "enabled": true
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="masonry-item col-md-6">
              <div className="bgc-white p-20 bd">
                <h6 className="c-grey-900">Delay Reason (>= 30 Mins)</h6>
                <div className="mT-30">
                  <Table bordered columns={columns} dataSource={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {})(OperationsReport);