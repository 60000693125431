import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form } from 'antd';
import { loginAction } from '../../actions/global';

const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  logIn = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginAction(values.username, values.password);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (this.props.token == null) {
      return (
        <div className="peers ai-s fxw-nw h-100vh">
          <div className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv" style={{ backgroundImage: `url("/assets/images/bg.jpg")` }}>
            <div className="pos-a centerXY">
              {/* <div className="bgc-white bdrs-50p pos-r" style={{width: '120px', height: '120px'}}>
              <img className="pos-a centerXY" src="/static/images/logo.jpg" alt="" style={{width: '100px'}}/>
            </div> */}
            </div>
          </div>
          <div className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r" style={{ minWidth: '320px' }}>
            <h4 className="fw-300 c-grey-900 mB-40">Skywise Login</h4>
            <Form onSubmit={this.logIn}>
              <div className="form-group">
                <label className="text-normal text-dark">Username</label>
                <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <input className="form-control" placeholder="Username" />
                  )}
                </FormItem>
              </div>
              <div className="form-group">
                <label className="text-normal text-dark">Password</label>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your password!' }],
                  })(
                    <input type="password" className="form-control" placeholder="Password" />
                  )}
                </FormItem>
              </div>
              <div className="form-group">
                <div className="peers ai-c jc-sb fxw-nw">
                  <div className="peer">
                    <div className="checkbox checkbox-circle checkbox-info peers ai-c">
                      <input type="checkbox" id="inputCall1" name="inputCheckboxesCall" className="peer" />
                      {/* <label className=" peers peer-greed js-sb ai-c">
                      <span className="peer peer-greed">Remember Me</span>
                    </label> */}
                    </div>
                  </div>
                  <div className="peer">
                    <button type="submit" className="btn btn-primary">Login</button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      );
    } else {
      return (
        <Redirect to={from} />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.global.token,
  };
}

let WrappedLogin = Form.create()(Login);

export default connect(mapStateToProps, {
  loginAction,
})(WrappedLogin);