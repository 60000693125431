import { LOGIN, LOGOUT, LOADING, STOP_LOADING } from '../types/global';
import _ from 'underscore';

export function checkLoginAction() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOADING,
      });
      const token = localStorage.getItem('token');
      if (token) {
        dispatch({
          type: LOGIN,
          token,
        });
      }
      dispatch({
        type: STOP_LOADING,
      });
      return resolve(token);
    });
  };
}

export function loginAction(username, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // dispatch({
      //   type: LOADING,
      // });
      if (username == 'admin' && password == 'admin') {
        // dispatch({
        //   type: STOP_LOADING,
        // });
        localStorage.setItem('token', 'xxxxxxxxxx');
        dispatch({
          type: LOGIN,
          token: 'xxxxxxxxxx',
        });
        window.location = '/';
        return resolve();
      } else {
        return reject('sai pass');
      }
      // return adminLogin(username, password)
      //   .then(res => {
      //     dispatch({
      //       type: STOP_LOADING,
      //     });
      //     let tmp;
      //     if (res.success) {
      //       tmp = res.data.items[0];
      //       localStorage.setItem('token', tmp.token);
      //       dispatch({
      //         type: LOGIN,
      //         token: tmp.token,
      //       });
      //       window.location = '/';
      //       return resolve();
      //     } else {
      //       return reject(res);
      //     }
      //   }).catch(err => {
      //     return reject(err);
      //   });
    });
  };
}

export function logoutAction() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
      window.location = '/';
      return resolve();
    });
  };
}