import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const Authenticated = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      rest.token ? (
        <Component {...props}/>
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: {from: props.location},
        }}/>
      )
    )}
    />
  );
};

function mapStateToProps(state) {
  return {
    token: state.global.token,
  };
}

export default connect(mapStateToProps)(Authenticated);