import React from 'react';
import { connect } from 'react-redux';

class Loading extends React.Component {
  render() {
    return (
      <div id="loader" className={`${!this.props.loading && 'fadeOut'}`}>
        <div className="spinner"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.global.loading,
  };
}

export default connect(mapStateToProps, {})(Loading);