import { LOGIN, LOGOUT, OFFLINE, LOADING, STOP_LOADING } from '../types/global';

const defaultState = {
  flagOffline: false,
  loading: false,
  token: null,
};

const global = (state = defaultState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case OFFLINE:
      return {
        ...state,
        flagOffline: action.status,
      };
    case LOGIN:
      return {
        ...state,
        token: action.token,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default global;
