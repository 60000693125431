import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Authenticated from '../../components/Authenticated';
import Loading from '../../components/Loading';
import SideBar from '../../components/Sidebar';
import HeaderBar from '../../components/HeaderBar';

import Login from '../../containers/Login';
import Dashboard from '../../containers/Dashboard';
import OperationsReport from '../../containers/OperationsReport';
import OilConsumption from '../../containers/OilConsumption';
import RecurrentDefects from '../../containers/RecurrentDefects';
import { checkLoginAction } from '../../actions/global';

class App extends React.Component {
  componentDidMount() {
    this.props.checkLoginAction();
  }

  render() {
    return (
      <page-body>
        <Loading />
        <div>
          {this.props.token &&
            <SideBar />
          }
          <div className={`${this.props.token && 'page-container'}`}>
            {this.props.token &&
              <HeaderBar />
            }
            <Router>
              <Switch>
                <Authenticated path="/" exact component={Dashboard} />
                <Authenticated path="/operations-report" exact component={OperationsReport} />
                <Authenticated path="/oil-consumption" exact component={OilConsumption} />
                <Authenticated path="/recurrent-defects" exact component={RecurrentDefects} />
                <Route path="/login" exact component={Login} />
              </Switch>
            </Router>
          </div>
        </div>
      </page-body>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.global.token,
  };
}

export default connect(mapStateToProps, { checkLoginAction })(App);